<template>
  <div class="columns is-multiline">
    <div class="column">
      <p class="title has-text-grey-light is-6">
        <span>Number of previous searches</span>
        <a
          class="icon has-text-info"
          v-if="count === 50"
          v-tippy="{ placement: 'right' }"
          title="History limited to the last 50 AutoChecks"
        >
          <i class="fal fa-info-circle" />
        </a>
      </p>
    </div>
    <div class="column is-narrow">
      <p class="title is-6">
        <span class="tag is-info">{{ countText }}</span>
      </p>
    </div>
    <table class="table is-fullwidth is-striped" v-if="count">
      <thead>
        <tr>
          <td>Type of organisation</td>
          <td>Date</td>
          <td>Time</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td width="33.33%">{{ item.typeOfBusiness }}</td>
          <td width="33.33%">
            {{ formatDate(item.dateOfTransaction, 'dd-MM-yyyy') }}
          </td>
          <td width="33.33%">{{ formatTime(item.timeOfTransaction) }}</td>
        </tr>
      </tbody>
    </table>
    <div
      class="column is-12 notification has-background-light warning-bar is-size-7"
      v-if="count > 5"
    >
      <div class="columns is-vcentered">
        <div class="column">
          <span class="icon has-text-danger">
            <i class="fal fa-exclamation-circle" />
          </span>
          <span>{{ showMoreText }}</span>
        </div>
        <div class="column is-narrow">
          <a @click="short = !short">
            <span>{{ showMoreCount }} </span>
            <span class="icon has-text-soft">
              <i class="fal" :class="showMoreIcon" />
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/date.utils.ts'
export default {
  name: 'AutoCheckPreviousSearches',
  props: {
    previousSearches: {
      type: Object,
      required: true
    }
  },
  data: () => ({ short: true }),
  computed: {
    count() {
      return this.previousSearches.searchItemsCount
    },
    countText() {
      const { count } = this
      if (count === 1) return '1 previous search'
      else return `${count} previous searches`
    },
    firstFiveItems() {
      return this.allItems.slice(0, 5)
    },
    allItems() {
      return this.previousSearches.searchItems
    },
    items() {
      return this.short ? this.firstFiveItems : this.allItems
    },
    showMoreCount() {
      const { short, count } = this
      const rest = count - 5
      const items = short ? `${rest} remaining items` : 'first 5 items only'
      return `Show ${items}`
    },
    showMoreText() {
      return `Showing ${this.short ? 5 : this.count} most recent checks`
    },
    showMoreIcon() {
      return this.short ? ' fa-chevron-double-down' : ' fa-chevron-double-up'
    }
  },
  methods: {
    formatDate,
    formatTime(value) {
      const hour = value.slice(1, 3)
      const minute = value.slice(3, 5)
      const second = value.slice(5)
      return `${hour}:${minute}:${second}`
    }
  }
}
</script>

<style lang="sass" scoped>
.warning-bar
  color: $warning-bar-text-colour !important
</style>